<template>
<div class="vw-100 mb-2" v-if="em">
  <!-- Config -->
  <div>
    Call Duration: {{ event.callTimer }} mins (first: {{ event.firstCallTimer }} mins)<br/>
    Questions: 
    <span v-if="event.workflowOptions.useQuestions">
      between {{ event.workflowOptions.questionsMinimum }} and {{ event.workflowOptions.questionsMaximum }}
    </span>
    <span v-else>
      no
    </span>
    <br/>
    Max Conversations Per User: {{ event.maxConversationPerUser }}<br/>
    Moderator Bot: {{ event.botId }}<br/>
    <span v-if="runningOnThisClient" class="text-danger">Running on this client</span>
    <span v-else-if="event.runningOnClient">Running on Client (not this one)</span>
    <span v-else>Running on Server</span><br/>
    State: {{ event.state }}<br/>
    <b v-if="event.eventComplete">
      EVENT COMPLETE
    </b>
    <!-- Event state -->
    <div>
      <div v-if="!event.state">
        <button class="btn btn-primary" @click="em.changeState('opendoors')">1. Open Doors</button>
        <p>Notice: Open doors means users can join the event and will be able to interact with moderator.</p>
      </div>
      <div v-if="event.state == 'opendoors'">
        <button class="btn btn-primary" @click="em.changeState('closeddoors')">2. Close Doors</button>
        <p>
          Notice: Closing doors means no new user can join the event. 
          However the event is not started.
          It allows moderator to respond to current users, without having new users join.
          It also will compute all the conversations possible.
        </p>
      </div>
      <div v-if="event.state == 'closeddoors'">
        <button class="btn btn-success" @click="em.changeState('running')">3. Start Event</button>
        <p>Notice: When the event starts, users will be sent the questions and get into conversations.</p>
      </div>
      <div v-if="event.state == 'running'">
        <button class="btn btn-success" @click="em.changeState('closed')">3. Close Event</button>
        <p>Notice: Wait a few mins before closing the event, as it will remove access to the chat.</p>
      </div>
    </div>
    <!-- Debug -->
    <div v-if="$debug.isDeveloperOn" class="alert-info">
      <div class="form-inline sub-mt-2 sub-mr-2">
        <div>Event state:</div>
        <button class="btn btn-outline-danger" @click="em.resetEvent()">Reset</button>
        <button class="btn btn-outline-danger" @click="em.changeState('opendoors')">Opendoors</button>
        <button class="btn btn-outline-danger" @click="em.changeState('closeddoors')">Closeddoors</button>
        <button class="btn btn-outline-danger" @click="em.changeState('running')">Running</button>
        <button class="btn btn-outline-danger" @click="em.changeState('closed')">Closed</button>
      </div>
      doorsClosed: {{ event.doorsClosed }}<br/>
    </div>
  </div>
  <!-- Conversations -->
  <div>
    <h2>Conversations</h2>
    <div class="mb-2" v-if="event.rounds && em && em.isReady">
      Rounds: {{ event.rounds.length }}<br/>
      <div class="mt-2" v-for="(round, index) in event.rounds" v-bind:key="'rnd_' + index">
        <b>Round {{ index + 1 }}</b>&nbsp;
        <button class="btn btn-primary badge" @click="em.startRound(round)" :disabled="!em.canStartRound(round)" v-if="runningOnThisClient && event.moderatorStartingCall">Start Round</button>
        <div class="form-inline sub-mr-2" v-for="(c, i) in round.conversations" v-bind:key="'rnd_c_' + i">
          <div>{{ c.u1.name }}</div>
          <div>/</div>
          <div>{{ c.u2.name }}</div>
          <div v-if="em.convStatus(c) == 'inprogress'">🗣</div>
          <div v-if="em.convStatus(c) == 'completed'">✔️</div>
          <div v-if="!em.convStatus(c) && runningOnThisClient && event.moderatorStartingCall">
            <button class="ml-2 btn btn-primary badge" @click="em.startConv(c)" :disabled="!em.canStartConv(c)">Start</button>
            <button class="ml-2 btn btn-outline-secondary badge" @click="em.excludeConv(c)">Exclude</button>
          </div>
        </div>
        <div class="d-flex small">
          <div class="mr-2">Sitting:</div>
          <div class="mr-2" v-for="(u, index) in round.sitting" v-bind:key="'sit_' + index">
            {{ u.name }}
          </div>
        </div>
      </div>
      <div v-if="event.supportsExcluded">
        <h3>Excluded</h3>
        <div v-if="!em.excluded || em.excluded.length == 0">None</div>
        <div v-for="(ex, index) in em.excluded" v-bind:key="'ex_' + index" v-else>
          <div class="d-flex">
            <div class="excluded">{{ ex.u1.name }} / {{ ex.u2.name }}</div>
            <button class="ml-2 btn btn-outline-secondary badge" @click="em.removeExcludedConv(ex)">x</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Not computed.
    </div>
  </div>
  <!-- Debug -->
  <div class="alert-info" v-if="$debug.isDeveloperOn">
    <h1>Debug</h1>
    <div>
      <button class="btn btn-primary" @click="setupTestMatchMakingEvent(event)">Test Mode</button> Set Short Duration.
      <debug-obj label="event.roundsInfo" :objData="event.roundsInfo"/>
    </div>
    <div v-if="$debug.isDeveloperOn" class="alert-info">
      <debug-group label="Running Options">
        pauseCheckNextStep: {{ event.pauseCheckNextStep }} <button class="btn btn-outline-warning" @click="updateEvent({pauseCheckNextStep:!event.pauseCheckNextStep})">Toggle</button><br/>
        runningOnClient: {{ event.runningOnClient }} <button class="btn btn-outline-warning" @click="updateEvent({runningOnClient:!event.runningOnClient})">Toggle</button><br/>
        <div v-if="event.runningOnClient">
          moderatorStartingCall: {{ event.moderatorStartingCall }} <button class="btn btn-outline-warning" @click="updateEvent({moderatorStartingCall:!event.moderatorStartingCall})">Toggle</button>
        </div>
        startRoundWhenAllReady: {{ event.startRoundWhenAllReady }} <button class="btn btn-outline-warning" @click="updateEvent({startRoundWhenAllReady:!event.startRoundWhenAllReady})">Toggle</button>
      </debug-group>
      <h3>Matching Options</h3>
      everyoneInterestBoth: {{ event.everyoneInterestBoth }} <button class="btn btn-outline-warning" @click="updateEvent({everyoneInterestBoth:!event.everyoneInterestBoth})">Toggle</button><br/>
      interestToOppositeGender: {{ event.interestToOppositeGender }} <button class="btn btn-outline-warning" @click="updateEvent({interestToOppositeGender:!event.interestToOppositeGender})">Toggle</button>
      <h3>Actions</h3>
      <div class="form-inline sub-mt-2 sub-mr-2">
        <button class="btn btn-outline-danger" @click="em.resetReady()">Set All Users Status Ready</button>
        <button class="btn btn-outline-danger" @click="em.updateConversationAndRounds()">Update Conversation And Rounds</button>
        <button class="btn btn-outline-danger" @click="sendMatchesInChat()">Compute matches</button>
      </div>
      <debug-group label="Conversations, Exclusions, Matches">
        <h3>Generated Conversations</h3>
        <div v-if="dbgConversations && dbgConversations.length">
          count: {{ dbgConversations.length }}
          <div v-for="c in dbgConversations" v-bind:key="'c' + c.id">
            {{ c.id }} {{ c.name }}
          </div>
        </div>
        <h3>Conversations</h3>
        <div v-if="conversations">
          <div v-for="c in conversations" v-bind:key="'ec' + c.id">
            {{ c.id }} {{ c.name }} {{ c.status }}
          </div>
        </div>
        <h3>Excluded</h3>
        <div v-if="excluded">
          <div v-for="ex in excluded" v-bind:key="'ex' + ex.id">
            {{ ex.id }} {{ ex }}
          </div>
        </div>
        <h3>Matches</h3>
        <div v-if="matches">
          <div v-for="m in matches" v-bind:key="'m' + m.id">
            {{ m.id }} {{ m }}
          </div>
        </div>
      </debug-group>
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('mixer-editor');
import { db } from "@/services/db";
import { EventManager } from "@/services/event-manager";
import { setupTestMatchMakingEvent } from "@/services/events";

export default {
  props: {
    event: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      em: null,

      // display
      rounds: null,
      eventUsers: null,
      conversations: [],
      excluded: [],
      matches: [],

      // debug
      dbgConversations: null,
    };
  },
  computed: {
    runningOnThisClient() {
      log.log(`runningOnThisClient ${this.event.runningOnClient} ${this.$debug.isSuperAdmin}`);
      return this.event && this.event.runningOnClient && this.$debug.isSuperAdmin;
    }
  },
  mounted() {
    log.log("mounted");
    this.em = new EventManager()
    this.em.init(this.event.id, true);
    this.em.updateCallback = (key, value) => {
      log.log(`updateCallback ${key}`, value);
      this[key] = value;
    };
    log.log("em = ", this.em);

    // Bind debug info
    this.$bind("eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
    this.$bind("conversations", db.collection(`LiveEvents/${this.event.id}/conversations`));
    this.$bind("excluded", db.collection(`LiveEvents/${this.event.id}/excluded`));
    this.$bind("matches", db.collection(`LiveEvents/${this.event.id}/matches`));

    // Auto update
    this.autoNextStepHandler = setInterval(() => {
      if (this.runningOnThisClient)
        this.em.checkNextStep();
    }, 5000);
  },
  beforeDestroy() {
    if (this.autoNextStepHandler)
      clearInterval(this.autoNextStepHandler);
    if (this.em)
      this.em.release();
  },
  methods: {
    setupTestMatchMakingEvent,
    // Event
    async updateEvent(data) {
      await db.collection(`LiveEvents`).doc(this.event.id).update(data);
    }
  }
}
</script>

<style>

</style>