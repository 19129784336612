import { getLog } from "./log";
import { db } from "./db";
import { setOrCreate } from "./dbutils";
let log = getLog('events');

export async function createEventFromTemplate(name, template, params) {
  log.log(`creating new event (${name}) from template: ${template}`);
  try {
    let def = await db.collection("LiveEvents").doc(template).get();
    let data = def.data();
    data.display = true;
    if (params)
      data = Object.assign(data, params);
    let doc = await setOrCreate(db.collection("LiveEvents"), name, data);
    log.log(`created new event ${doc.id}`);
    return doc;
  } catch (e) {
    log.error(`Failed to create event ${name}`, e);
  }
}

export async function resetEventFromTemplate(event) {
  let templateRef = await db.collection("LiveEvents").doc(event.template).get();
  let data = Object.assign(event, templateRef.data());
  log.log("resetEventFromTemplate merged result:", data);
  await db.collection("LiveEvents").doc(event.id).set(data);
  log.log(`reset event ${event.id}`, data);
}

export async function setupTestMatchMakingEvent(event) {
  log.log("will configure for unit test");
  let data = {
    callTimer: 0.5,
    firstCallTimer: 0.5,
  };
  await db.collection("LiveEvents").doc(event.id).set(data, {merge:true});
}