<template>
<div>
  <div class="rooms">
  <div class="d-flex">
    <h1 style="display: inline;" class="mr-4">Rooms</h1>
    <div class="align-self-center">
    <select @change="updateRoomsFilter">
        <option value="recentonly">Recent only</option>
        <option value="archived">Archived</option>
    </select>
    <button class="btn btn-primary ml-3" @click="showCreateRoom = !showCreateRoom">Create Room</button>
    </div>
  </div>
  <div class="scrollable-x">
  <div class="d-flex">
    <!-- Room Creator -->
    <div class="mr-3" v-if="showCreateRoom">
      <h2>Create Room</h2>
      <div>
        <div v-for="i in [1,2,3,4]" v-bind:key="i">
          Person {{ i }}: 
          <select ref="invited">
            <option value="none">None</option>
            <template v-for="user in users">
              <option :key="user.id" :value="user.id">{{ user.name }} - {{ user.id }}</option>
            </template>
            <option value="studio-01">Studio (WIP) - studio-01</option>
          </select>
        </div>
        Moderator: <b-form-select class="d-inline-block" v-model="bot" :options="botList"></b-form-select>
        Video: <input type="checkbox" v-model="video"/><br/>
        Timer: <input type="checkbox" v-model="timer"/><input type="number" v-model="timer"/><br/>
        <div v-if="$debug.isDeveloperOn" class="alert-info">
          Content Test Questions: <input type="checkbox" v-model="testQuestions"><br/>
        </div>
        Content: <br/>
        <textarea rows="5" cols="50" v-model="content" placeholder="<Paste here the questions>"/><br/>
        <button class="btn btn-primary" @click.prevent="createRoom">Create and Invite</button>
      </div>
    </div>
    <!-- Active Rooms -->
    <div class="mr-3" v-for="(room, i) in rooms" v-bind:key="room.id">
      <h2 v-b-tooltip.click.top="room.id">
        <template v-if="!room.name">Room {{ i + 1 }}</template>
        <template v-else>{{ room.name }}</template>
      </h2>
      <button class="align-self-center btn badge btn-outline-secondary" @click="editedRoom[room.id] = !editedRoom[room.id]">
        Edit
        </button>
      <button class="align-self-center btn badge btn-outline-danger" 
        @click="deleteRoom(room.id)" v-if="$debug.isSuperAdminOn">Delete</button>
      <p>
        Timer: {{ roomTimers[room.id] }}
        <button class="align-self-center btn badge btn-outline-danger" 
        @click="closeRoom(room.id)" v-if="$debug.isDeveloperOn">Close</button>
      </p>
      <div v-if="$debug.isDeveloperOn">
        <debug-obj label="room" :objData="room"/>
      </div>
      <div v-if="editedRoom[room.id]">
        <roomeditor v-bind:room="room" v-on:done="editedRoom[room.id] = false"/>
      </div>
      <div v-else>
        <p class="bg-light rounded p-1" style="white-space:pre;">{{ room.content }}</p>
      </div>
      <div v-if="usersMap">
        <div v-for="user in room.users.filter(u => u != 'none')" v-bind:key="user">
          {{ usersMap[user] ? usersMap[user].name : user }}
        </div>
      </div>
    </div>
    <div class="col" v-if="rooms.length == 0">
      <p>No room listed, please change filter to see more</p>
    </div>
  </div>
  </div>
  </div>
  <div v-if="$debug.isDeveloperOn" class="alert-info">
    <h2>Debug</h2>
    <button class="btn btn-outline-warning" @click="closeAllRooms()">Close all Rooms</button><br/>
    <button class="btn btn-outline-danger" @click="deleteAllRooms()">Delete all Rooms</button><br/>
  </div>
</div>
</template>

<script>
import { db } from '@/services/db';
import roomeditor from "@/components/roomeditor";
import { createRoomWithData, sendRoomLink } from "@/services/room";
import { formatTimer } from "@/services/utils";
import { serverEpoch } from '@/services/server-time';

export default {
  components: {
    roomeditor,
  },
  props: {
    users: Array,
    usersMap: Object,
  },
  data() {
    return {
      showCreateRoom: false,
      rooms: [],
      roomsFilter: "",

      timer: 5.5,
      video: false,
      testQuestions: false,
      content: "",
      bot: null,
      botList: [
        { value: null, text:"No bot" },
        { value: "JakeBot", text: "Moderator Jake" }
      ],

      roomTimers: {},
      editedRoom: {},
    }
  },
  mounted() {
    this.updateRoomsFilter();
    setInterval(this.updateTimers, 1000);
  },
  methods: {
    deleteRoom(roomId) {
      db.collection("LiveRooms").doc(roomId).delete();
    },
    closeRoom(roomId) {
      db.collection("LiveRooms").doc(roomId).update({timer:0.01});
    },
    closeAllRooms() {
      let batch = db.batch();
      this.rooms.forEach(r => {
        batch.update(db.collection("LiveRooms").doc(r.id), {timer:0});
      });
      batch.commit();
    },
    deleteAllRooms() {
      let batch = db.batch();
      this.rooms.forEach(r => {
        batch.delete(db.collection("LiveRooms").doc(r.id));
      });
      batch.commit();
    },
    updateRoomsFilter(event) {
      let filter = event ? event.target.value : "recentonly";
      if (!filter || filter == this.roomsFilter)
        return;
      this.roomsFilter = filter;
      if (filter == "recentonly") {
        let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        this.$bind("rooms", db.collection("LiveRooms").where("startTime", ">", yesterday).orderBy("startTime"));
      }
      else
        this.$bind("rooms", db.collection("LiveRooms").orderBy("startTime"));
    },
    updateTimers() {
      var res = {};
      for (let r of this.rooms)
        res[r.id] = r.timer ? formatTimer(r.startTime.seconds, r.timer, serverEpoch()) : "None";
      this.roomTimers = res;
    },
    async createRoom() {
      let content = this.content;
      if (this.testQuestions)
        content = {
          type: "questions",
          questions: [
            { "author": "Karen", "question" : "If you had to live abroad for 6 months starting tomorrow, where would you go?" },
            { "author": "Karen", "question" : "Name a couple things that always keep you grounded." },
            { "author": "Jack", "question" : "What's the biggest risk you've taken in the last year?" },
            { "author": "Jack", "question" : "What's the most underrated movie of all-time?" }
          ]
        }
      let users = this.$refs.invited.map((e) => e.value).filter((e) => e != "none");
      let r = await createRoomWithData({
        users: users,
        timer: this.timer,
        content,
        bot: this.bot,
        video: this.video,
      });
      sendRoomLink(r.id, users);
    }
  }
}
</script>

<style css scoped>
.scrollable-x {
  overflow: auto;
  overflow-x: scroll;
  width: calc(100vw - 200px);
}
</style>